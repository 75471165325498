var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main user-layout-register" },
    [
      _vm._m(0),
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.model, rules: _vm.validatorRules } },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "name",
                label: "学生姓名",
                wrapperCol: { span: 24 }
              }
            },
            [
              _c("a-input", {
                attrs: {
                  size: "large",
                  type: "text",
                  autocomplete: "false",
                  placeholder: "请输入学生姓名"
                },
                model: {
                  value: _vm.model.name,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "idNumber",
                required: "",
                label: "身份证号",
                wrapperCol: { span: 24 }
              }
            },
            [
              _c("a-input", {
                attrs: {
                  size: "large",
                  type: "text",
                  autocomplete: "false",
                  placeholder: "请输入身份证号"
                },
                model: {
                  value: _vm.model.idNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "idNumber", $$v)
                  },
                  expression: "model.idNumber"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "学生编号", wrapperCol: { span: 24 } } },
            [
              _c("a-input", {
                attrs: {
                  size: "large",
                  type: "text",
                  autocomplete: "false",
                  placeholder: "请输入学生编号"
                },
                model: {
                  value: _vm.model.code,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "code", $$v)
                  },
                  expression: "model.code"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "sex-form",
              attrs: {
                label: "性别",
                required: "",
                labelCol: { span: 5 },
                wrapperCol: { span: 19 },
                prop: "gender"
              }
            },
            [
              _c("j-dict-select-tag", {
                attrs: {
                  type: "radio",
                  dictCode: "sex",
                  placeholder: "请选择性别"
                },
                model: {
                  value: _vm.model.gender,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "gender", $$v)
                  },
                  expression: "model.gender"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "selectBox",
              attrs: {
                label: "学校名称",
                wrapperCol: { span: 24 },
                prop: "schoolIdCode"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  "default-active-first-option": "false",
                  "filter-option": false,
                  "not-found-content": null,
                  options: _vm.schoolData,
                  "show-arrow": false,
                  "show-search": "",
                  "show-placeholder": "",
                  placeholder: "请输入学校名称进行搜索"
                },
                on: { search: _vm.handleSearch, change: _vm.roomChange },
                model: {
                  value: _vm.model.schoolIdCode,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "schoolIdCode", $$v)
                  },
                  expression: "model.schoolIdCode"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "院系",
                prop: "departmentId",
                required: "",
                wrapperCol: { span: 24 }
              }
            },
            [
              _c(
                "a-select",
                {
                  on: { change: _vm.baseMajorAll },
                  model: {
                    value: _vm.model.departmentId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "departmentId", $$v)
                    },
                    expression: "model.departmentId"
                  }
                },
                _vm._l(_vm.departmentList, function(item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "专业",
                prop: "majorId",
                required: "",
                wrapperCol: { span: 24 }
              }
            },
            [
              _c(
                "a-select",
                {
                  on: { change: _vm.baseClassAll },
                  model: {
                    value: _vm.model.majorId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "majorId", $$v)
                    },
                    expression: "model.majorId"
                  }
                },
                _vm._l(_vm.majorList, function(item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "班级",
                prop: "classId",
                required: "",
                wrapperCol: { span: 24 }
              }
            },
            [
              _c(
                "a-select",
                {
                  on: { change: _vm.onChange1 },
                  model: {
                    value: _vm.model.classId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "classId", $$v)
                    },
                    expression: "model.classId"
                  }
                },
                _vm._l(_vm.classList, function(item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "组织",
                prop: "relTenantIds",
                required: "",
                wrapperCol: { span: 24 }
              }
            },
            [
              _c(
                "a-select",
                {
                  attrs: { mode: "multiple" },
                  model: {
                    value: _vm.model.relTenantIds,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "relTenantIds", $$v)
                    },
                    expression: "model.relTenantIds"
                  }
                },
                _vm._l(_vm.tenantList, function(item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "mobile" } },
            [
              _c(
                "a-input",
                {
                  attrs: { size: "large", placeholder: "11 位手机号" },
                  model: {
                    value: _vm.model.mobile,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "mobile", $$v)
                    },
                    expression: "model.mobile"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        slot: "addonBefore",
                        size: "large",
                        defaultValue: "+86"
                      },
                      slot: "addonBefore"
                    },
                    [
                      _c("a-select-option", { attrs: { value: "+86" } }, [
                        _vm._v("+86")
                      ]),
                      _c("a-select-option", { attrs: { value: "+87" } }, [
                        _vm._v("+87")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "a-col",
                { staticClass: "gutter-row", attrs: { span: 16 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "captcha" } },
                    [
                      _c(
                        "a-input",
                        {
                          attrs: {
                            size: "large",
                            type: "text",
                            placeholder: "验证码"
                          },
                          model: {
                            value: _vm.model.captcha,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "captcha", $$v)
                            },
                            expression: "model.captcha"
                          }
                        },
                        [
                          _c("a-icon", {
                            style: { color: "rgba(0,0,0,.25)" },
                            attrs: { slot: "prefix", type: "mail" },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { staticClass: "gutter-row", attrs: { span: 8 } },
                [
                  _c("a-button", {
                    staticClass: "getCaptcha",
                    attrs: { size: "large", disabled: _vm.state.smsSendBtn },
                    domProps: {
                      textContent: _vm._s(
                        (!_vm.state.smsSendBtn && "获取验证码") ||
                          _vm.state.time + " s"
                      )
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.getCaptcha($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "a-button",
                {
                  staticClass: "register-button",
                  attrs: {
                    size: "large",
                    type: "primary",
                    htmlType: "submit",
                    loading: _vm.registerBtn,
                    disabled: _vm.registerBtn
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleSubmit($event)
                    }
                  }
                },
                [_vm._v("注册\n        ")]
              ),
              _c("router-link", {
                staticClass: "login",
                attrs: { to: { name: "login" } }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "register_title" }, [
      _c("span", [_vm._v("毅力实验通"), _c("br"), _vm._v("学生注册")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }