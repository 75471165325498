var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { type: "flex", gutter: 16 } },
    [
      _c(
        "a-col",
        { attrs: { md: 5, sm: 24 } },
        [
          _c("address-list-left", {
            model: {
              value: _vm.currentOrgCode,
              callback: function($$v) {
                _vm.currentOrgCode = $$v
              },
              expression: "currentOrgCode"
            }
          })
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { md: 24 - 5, sm: 24 } },
        [
          _c("address-list-right", {
            model: {
              value: _vm.currentOrgCode,
              callback: function($$v) {
                _vm.currentOrgCode = $$v
              },
              expression: "currentOrgCode"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }