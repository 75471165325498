<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <!-- 主表单区域 -->
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="12" >
            <a-form-model-item label="设备名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name">
              <a-input v-model="model.name" placeholder="请输入设备名称" ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" >
            <a-form-model-item label="设备分类编码" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="code">
              <a-input v-model="model.code" placeholder="请输入设备编号" ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" >
            <a-form-model-item label="分类" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="category">
              <j-dict-select-tag type="list" v-model="model.category" dictCode="assets_category" placeholder="请选择分类" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12" >
            <a-form-model-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="status">
              <j-dict-select-tag type="radio" v-model="model.status" dictCode="status" placeholder="请选择状态" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12" >
            <a-form-model-item label="自动审批" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="automaticApproval">
              <j-dict-select-tag type="radio" v-model="model.automaticApproval" dictCode="automatic_status" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12" >
            <a-form-model-item label="封面" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="coverUrl">
              <j-image-upload  v-model="model.coverUrl" ></j-image-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" >
            <a-form-model-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="remark">
              <a-textarea v-model="model.remark" rows="4" placeholder="请输入备注" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
      <!-- 子表单区域 -->
    <a-tabs v-model="activeKey" @change="handleChangeTabs">
      <a-tab-pane tab="设备明细" :key="refKeys[0]" :forceRender="true">
        <j-vxe-table
          keep-source
          :ref="refKeys[0]"
          :loading="assetsInfoTable.loading"
          :columns="assetsInfoTable.columns"
          :dataSource="assetsInfoTable.dataSource"
          :maxHeight="300"
          :disabled="formDisabled"
          :rowNumber="true"
          :rowSelection="true"
          :toolbar="true"
          />
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>

<script>

  import { getAction } from '@/api/manage'
  import { JVxeTableModelMixin } from '@/mixins/JVxeTableModelMixin.js'
  import { JVXETypes } from '@/components/jeecg/JVxeTable'
  import { getRefPromise,VALIDATE_FAILED} from '@/components/jeecg/JVxeTable/utils/vxeUtils.js'
  import { validateDuplicateValue } from '@/utils/util'
  import JFormContainer from '@/components/jeecg/JFormContainer'

  export default {
    name: 'AssetsForm',
    mixins: [JVxeTableModelMixin],
    components: {
      JFormContainer,
    },
    data() {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        model:{
         },
        // 新增时子表默认添加几行空数据
        addDefaultRowNum: 1,
        validatorRules: {
           name: [
              { required: true, message: '请输入设备名称!'},
              { validator: (rule, value, callback) => validateDuplicateValue('assets', 'name', value, this.model.id, callback)},
           ],
           code: [
              { required: true, message: '请输入设备编号!'},
              { validator: (rule, value, callback) => validateDuplicateValue('assets', 'code', value, this.model.id, callback)},
           ],
           status: [
              { required: true, message: '请输入状态!'},
           ],
        },
        refKeys: ['assetsInfo', ],
        tableKeys:['assetsInfo', ],
        activeKey: 'assetsInfo',
        // 设备明细
        assetsInfoTable: {
          loading: false,
          dataSource: [],
          columns: [
            {
              title: '设备编号',
              key: 'code',
              type: JVXETypes.input,
              width:"200px",
              placeholder: '请输入${title}',
              defaultValue:'',
              validateRules: [{ required: true, message: '${title}不能为空' }],
            },
            {
              title: '状态',
              key: 'status',
              type: JVXETypes.select,
              options:[],
              dictCode:"assets_status",
              disabled:true,
              width:"200px",
              placeholder: '请输入${title}',
              defaultValue:0,
              validateRules: [{ required: true, message: '${title}不能为空' }],
            },
            {
              title: '上架状态',
              key: 'putOn',
              //type: JVXETypes.select,
              //options:[],
              //dictCode:"put_on",
              //dataIndex: 'put_on',
            customRender: (value, row, index) => {
              let re = "";
              if (text === '1') {
                re = "国内订单";
              } else if (text === '2') {
                re = "国际订单";
              }
              return re;
            }
              //disabled:true,
              //width:"200px",
              //placeholder: '请输入${title}',
              //defaultValue:0,
              //validateRules: [{ required: true, message: '${title}不能为空' }],

            },
            {
              title: '备注',
              key: 'remark',
              type: JVXETypes.textarea,
              width:"200px",
              placeholder: '请输入${title}',
              defaultValue:'',
            },
          ]
        },
        url: {
          add: "/ylpp/assets/add",
          edit: "/ylpp/assets/edit",
          queryById: "/ylpp/assets/queryById",
          assetsInfo: {
            list: '/ylpp/assets/queryAssetsInfoByMainId'
          },
        }
      }
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
    },
    methods: {
      addBefore(){
        this.assetsInfoTable.dataSource=[]
      },
      getAllTable() {
        let values = this.tableKeys.map(key => getRefPromise(this, key))
        return Promise.all(values)
      },
      /** 调用完edit()方法之后会自动调用此方法 */
      editAfter() {
        this.$nextTick(() => {
        })
        // 加载子表数据
        if (this.model.id) {
          let params = { id: this.model.id }
          this.requestSubTableData(this.url.assetsInfo.list, params, this.assetsInfoTable)
        }
      },
      //校验所有一对一子表表单
        validateSubForm(allValues){
            return new Promise((resolve,reject)=>{
              Promise.all([
              ]).then(() => {
                resolve(allValues)
              }).catch(e => {
                if (e.error === VALIDATE_FAILED) {
                  // 如果有未通过表单验证的子表，就自动跳转到它所在的tab
                  this.activeKey = e.index == null ? this.activeKey : this.refKeys[e.index]
                } else {
                  console.error(e)
                }
              })
            })
        },
      /** 整理成formData */
      classifyIntoFormData(allValues) {
        let main = Object.assign(this.model, allValues.formValue)
        return {
          ...main, // 展开
          assetsInfoList: allValues.tablesValue[0].tableData,
        }
      },
      validateError(msg){
        this.$message.error(msg)
      },

    }
  }
</script>

<style scoped>
</style>