var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index1" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "a-form-model",
          {
            ref: "form",
            attrs: { model: _vm.formState, rules: _vm.validatorRules }
          },
          [
            _c(
              "a-form-model-item",
              {
                attrs: { required: "", prop: "orgName", label: "学校/企业名称" }
              },
              [
                _c("a-input", {
                  attrs: { placeholder: "请输入学校/企业名称" },
                  model: {
                    value: _vm.formState.orgName,
                    callback: function($$v) {
                      _vm.$set(_vm.formState, "orgName", $$v)
                    },
                    expression: "formState.orgName"
                  }
                })
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { required: "", label: "姓名", prop: "name" } },
              [
                _c("a-input", {
                  attrs: { placeholder: "请输入姓名" },
                  model: {
                    value: _vm.formState.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formState, "name", $$v)
                    },
                    expression: "formState.name"
                  }
                })
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "职务" } },
              [
                _c("a-input", {
                  attrs: { placeholder: "请输入职务" },
                  model: {
                    value: _vm.formState.duties,
                    callback: function($$v) {
                      _vm.$set(_vm.formState, "duties", $$v)
                    },
                    expression: "formState.duties"
                  }
                })
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "手机号", prop: "phone" } },
              [
                _c("a-input", {
                  attrs: { placeholder: "请输入手机号" },
                  model: {
                    value: _vm.formState.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.formState, "phone", $$v)
                    },
                    expression: "formState.phone"
                  }
                })
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "省", prop: "provinceCode" } },
              [
                _c(
                  "a-select",
                  {
                    attrs: { placeholder: "请选择省份" },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.formState.provinceCode,
                      callback: function($$v) {
                        _vm.$set(_vm.formState, "provinceCode", $$v)
                      },
                      expression: "formState.provinceCode"
                    }
                  },
                  _vm._l(_vm.pronceList, function(item) {
                    return _c(
                      "a-select-option",
                      {
                        key: item.code,
                        attrs: { value: item.code, title: item.name }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { attrs: { label: "市", prop: "cityCode" } },
              [
                _c(
                  "a-select",
                  {
                    attrs: { placeholder: "请选择地市" },
                    on: { change: _vm.onChange1 },
                    model: {
                      value: _vm.formState.cityCode,
                      callback: function($$v) {
                        _vm.$set(_vm.formState, "cityCode", $$v)
                      },
                      expression: "formState.cityCode"
                    }
                  },
                  _vm._l(_vm.cityList, function(item) {
                    return _c(
                      "a-select-option",
                      {
                        key: item.code,
                        attrs: { value: item.code, title: item.name }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { staticClass: "wer", attrs: { label: "" } },
              [
                _c(
                  "a-checkbox",
                  {
                    model: {
                      value: _vm.checked,
                      callback: function($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked"
                    }
                  },
                  [_vm._v("我已经阅读并同意\n          ")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "a_href",
                    on: {
                      click: function($event) {
                        return _vm.link("ys")
                      }
                    }
                  },
                  [_vm._v("《隐私条款》")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "a_href",
                    on: {
                      click: function($event) {
                        return _vm.link("fw")
                      }
                    }
                  },
                  [_vm._v("《用户协议》")]
                )
              ],
              1
            ),
            _c(
              "a-form-model-item",
              { staticClass: "wer1", attrs: { label: "" } },
              [
                _c("p", { staticClass: "describe" }, [
                  _vm._v("\n          温馨提示:毅力实验通产品讲解预约"),
                  _c("br"),
                  _vm._v(
                    "\n          1、你的企业可免费享受一次价值1000元的产品顾问一对一讲解服务。"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          2、服务由毅力实验通区域服务中心提供，时长约45-60分钟，包含毅力实验通产品功能讲解和行业优秀案例分享。\n        "
                  )
                ])
              ]
            ),
            _c(
              "a-form-model-item",
              {
                staticClass: "btn",
                attrs: { "wrapper-col": { span: 14, offset: 4 } }
              },
              [
                _c(
                  "a-button",
                  {
                    staticClass: "aBtn",
                    attrs: { type: "primary" },
                    on: { click: _vm.onSubmit }
                  },
                  [_vm._v("提交")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "slogan" }, [_vm._v("产品体验，免费试用")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }