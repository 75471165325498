<template>
  <div class="main user-layout-register">
    <h3 class="register_title"><span>毅力实验通<br />学生注册</span></h3>
    <a-form-model ref="form" :model="model" :rules="validatorRules">
      <a-form-model-item prop="name" label="学生姓名"
        :wrapperCol="{ span: 24 }">
        <a-input v-model="model.name" size="large" type="text" autocomplete="false" placeholder="请输入学生姓名" />
      </a-form-model-item>
      <a-form-model-item prop="idNumber" required label="身份证号"
        :wrapperCol="{ span: 24 }">
        <a-input v-model="model.idNumber" size="large" type="text" autocomplete="false" placeholder="请输入身份证号" />
      </a-form-model-item>
      <a-form-model-item  label="学生编号"
        :wrapperCol="{ span: 24 }">
        <a-input v-model="model.code" size="large" type="text" autocomplete="false" placeholder="请输入学生编号" />
      </a-form-model-item>
      <a-form-model-item class="sex-form" label="性别" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" prop="gender">
        <j-dict-select-tag type="radio" v-model="model.gender" dictCode="sex" placeholder="请选择性别" />
      </a-form-model-item>
      <a-form-model-item
        class="selectBox"
        label="学校名称"
        :wrapperCol="{ span: 24 }"
        prop="schoolIdCode"
      >
        <a-select
          v-model:value="model.schoolIdCode"
          default-active-first-option="false"
          :filter-option="false"
          :not-found-content="null"
          :options="schoolData"
          :show-arrow="false"
          show-search
          show-placeholder
          @search="handleSearch"
          @change="roomChange"
          placeholder="请输入学校名称进行搜索"
        >
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item prop="code" label="学校编号"
        :wrapperCol="{ span: 24 }">
        <a-input v-model="model.code" size="large" type="text" autocomplete="false" placeholder="请输入学生编号" />
      </a-form-model-item> -->
      <a-form-model-item label="院系" prop="departmentId" required :wrapperCol="{ span: 24 }" >
        <a-select   v-model="model.departmentId" @change="baseMajorAll">
          <a-select-option v-for="(item, index) in departmentList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="专业" prop="majorId" required :wrapperCol="{ span: 24 }" >
        <a-select   v-model="model.majorId" @change="baseClassAll">
          <a-select-option v-for="(item, index) in majorList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="班级" prop="classId" required :wrapperCol="{ span: 24 }" >
        <a-select   v-model="model.classId" @change="onChange1">
          <a-select-option v-for="(item, index) in classList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="组织" prop="relTenantIds" required :wrapperCol="{ span: 24 }" >
        <a-select  mode="multiple" v-model="model.relTenantIds">
          <a-select-option v-for="(item, index) in tenantList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="mobile">
        <a-input v-model="model.mobile" size="large" placeholder="11 位手机号">
          <a-select slot="addonBefore" size="large" defaultValue="+86">
            <a-select-option value="+86">+86</a-select-option>
            <a-select-option value="+87">+87</a-select-option>
          </a-select>
        </a-input>
      </a-form-model-item>
      <!--<a-input-group size="large" compact>
            <a-select style="width: 20%" size="large" defaultValue="+86">
              <a-select-option value="+86">+86</a-select-option>
              <a-select-option value="+87">+87</a-select-option>
            </a-select>
            <a-input style="width: 80%" size="large" placeholder="11 位手机号"></a-input>
          </a-input-group>-->

      <a-row :gutter="16">
        <a-col class="gutter-row" :span="16">
          <a-form-model-item prop="captcha">
            <a-input v-model="model.captcha" size="large" type="text" placeholder="验证码">
              <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <a-button
            class="getCaptcha"
            size="large"
            :disabled="state.smsSendBtn"
            @click.stop.prevent="getCaptcha"
            v-text="(!state.smsSendBtn && '获取验证码') || state.time + ' s'"
          ></a-button>
        </a-col>
      </a-row>

      <a-form-model-item style="
    text-align: center;
">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="register-button"
          :loading="registerBtn"
          @click.stop.prevent="handleSubmit"
          :disabled="registerBtn"
          >注册
        </a-button>
        <router-link class="login" :to="{ name: 'login' }"> </router-link>
      </a-form-model-item>
    </a-form-model>
  </div>
  <!-- <div v-else>
    <p style="color:red;font-size:20px;text-align:center;">该链接已失效!</p>
  </div> -->
</template>

<script>
import { mixinDevice } from '@/utils/mixin.js'
import { getSmsCaptcha } from '@/api/login'
import { getAction, postAction } from '@/api/manage'
import { checkOnlyUser } from '@/api/api'

const levelNames = {
  0: '低',
  1: '低',
  2: '中',
  3: '强',
}
const levelClass = {
  0: 'error',
  1: 'error',
  2: 'warning',
  3: 'success',
}
const levelColor = {
  0: '#ff0000',
  1: '#ff0000',
  2: '#ff7e05',
  3: '#52c41a',
}
export default {
  name: 'Register',
  components: {},
  mixins: [mixinDevice],
  data() {
    return {
      showRegister:true,
      departmentList:[],
      classList:[],
      majorList:[],
      schoolData:[],
      tenantList:[],
      options: [],
      model: {
        classId:"",
        idNumber:"",
        name:'',
        registerStatus:0

      },
      loading: false,
      validatorRules: {
        name: [{ required: true , message: '请输入姓名!' }],
        captcha: [{ required: true, message: '请输入验证码!'  }],
        code: [{ required: true, message: '请输入学生编码!' }],
        classIds: [{ required: true, message: '请选择班级!' }],
        relTenantIds: [{ required: true, message: '请选择组织!' }],
        schoolIdCode: [{ required: true, message: '请选择学校名称!' }],
        gender: [{ required: true, message: '请输入性别!' }],
        departmentId: [{ required: true, message: '请选择院系!' }],
        majorId: [{ required: true, message: '请选择专业!' }],
        classId: [{ required: true, message: '请输入班级!' }],
        idNumber: [{required: true, message: '请输入身份证号!'},{validator: this.validateIdCard}],
        mobile: [
          { required: true, message: '请输入手机号!' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码!' },
        ],
      },
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: '#FF0000',
      },
      url: {
        querySchool: '/api/university/list',
        baseDepartmentAll: 'ylpp/baseDepartment/all',
        baseMajorAll: 'ylpp/baseMajor/linkage',
        baseClassAll: 'ylpp/tbClass/pulldown/major',
        tenantAll: 'sys/tenant/all',
        register: 'ylpp/student/register',
      },
      registerBtn: false,
    }
  },
  computed: {
    passwordLevelClass() {
      return levelClass[this.state.passwordLevel]
    },
    passwordLevelName() {
      return levelNames[this.state.passwordLevel]
    },
    passwordLevelColor() {
      return levelColor[this.state.passwordLevel]
    },
  },
  mounted(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let time = urlParams.get('time');
    time = Number(time)+Number(86400000);
    let newTime= new Date().getTime();
    if(time >= newTime){
        this.showRegister=true;
    }else{
      this.showRegister=false;
    }
  },
  methods: {
    handlePhoneCheck(rule, value, callback) {
      var reg = /^1[3456789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入正确手机号'))
      } else {
        var params = {
          phone: value,
        }
        checkOnlyUser(params).then((res) => {
          if (res.success) {
            callback()
          } else {
            callback('手机号已存在!')
          }
        })
      }
    },
    handleSubmit() {
      this.$refs['form'].validate((success) => {
        if (success == true) {
          let values = this.model;
          this.model.birthday= this.analyzeIDCard(this.model.idNumber);
          this.model.phone = this.model.mobile;
          postAction('ylpp/student/register', this.model).then((res) => {
            if (!res.success) {
              this.registerFailed(res.message)
            } else {
              this.$router.push({ name: 'registerResult', params: { ...values } })
            }
          })
        }
      })
    },
    getCaptcha(e) {
      e.preventDefault()
      let that = this
      this.$refs['form'].validateField(['mobile'], (err) => {
        if (!err) {
          this.state.smsSendBtn = true
          let interval = window.setInterval(() => {
            if (that.state.time-- <= 0) {
              that.state.time = 60
              that.state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)
          const hide = this.$message.loading('验证码发送中..', 3)
          const params = {
            model: this.model.mobile,
            type: '1',
            userType: 2,
          }
          postAction('/sys/sms/code', params)
            .then((res) => {
              if (!res.success) {
                this.registerFailed(res.message)
                setTimeout(hide, 0)
              }
              setTimeout(hide, 500)
            })
            .catch((err) => {
              setTimeout(hide, 1)
              clearInterval(interval)
              that.state.time = 60
              that.state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    registerFailed(message) {
      this.$notification['error']({
        message: '注册失败',
        description: message,
        duration: 2,
      })
    },
    requestFailed(err) {
      this.$notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4,
      })
      this.registerBtn = false
    },
    async handleSearch(val) {
      console.log(val)
      this.schoolData = []
      let params = { name: val, pageNo: 1, pageSize: 100 }
      getAction(this.url.querySchool, params).then((res) => {
        if (res.success) {
          res.result.records.forEach((item) => {
            this.schoolData.push({
              //循环，把值装进去
              value: item.code,
              label: item.name,
            })
          })
        }
      })
    },onChange1(value) {
      if(this.model.classIds.indexOf(value[0])==-1){
        this.model.classIds.push(value[0])
      }
    },roomChange(val) {
      this.model.departmentId=""
      this.model.majorId=""
      this.model.classId=""
      this.departmentList=[];
      this.classList=[];
      this.majorList=[];
      let that = this
      this.schoolData.forEach((item) => {
        if (val == item.value) {
          this.model.schoolName = item.label;
          that.baseDepartmentAll()
          this.tenantAll()
        }
      })
    },baseDepartmentAll() {
      this.departmentList = []
      let params = {
        schoolCode: this.model.schoolIdCode
      }
      getAction(this.url.baseDepartmentAll, params).then((res) => {
        if (res.success) {
          this.departmentList=res.result
        }
      })
    },onChange1(arr){
      console.log(arr)
    },
    analyzeIDCard(IDCard) {
      //获取用户身份证号码
        //获取出生年月日
        var yearBirth = IDCard.substring(6, 10);
        var monthBirth = IDCard.substring(10, 12);
        var dayBirth = IDCard.substring(12, 14);
        return yearBirth + "-" + monthBirth + "-" + dayBirth;
    },
    tenantAll() {
      this.tenantList = []
      let params = {
        schoolCode: this.model.schoolIdCode,
      }
      getAction(this.url.tenantAll, params).then((res) => {
        if (res.success) {
          this.tenantList = res.result
        }
      })
    },
    loadData(selectedOptions) {
      let option = selectedOptions[selectedOptions.length - 1]
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      if (option.level == 1) {
        this.baseMajorAll(option)
          .then((res) => {
            targetOption.loading = false
            targetOption.children = res
            this.options = [...this.options]
          })
          .catch((err) => {
            console.log(err)
            //this.$Message.error(err)
            targetOption.loading = false
          })
      } else if (option.level == 2) {
        this.baseClassAll(option)
          .then((res) => {
            targetOption.loading = false
            targetOption.children = res
            this.options = [...this.options]
          })
          .catch((err) => {
            console.log(err)
            targetOption.loading = false
          })
      }
      // // load options lazily
      // setTimeout(() => {
      //   targetOption.loading = false;
      //   targetOption.children = [
      //     {
      //       label: `${targetOption.label} Dynamic 1`,
      //       value: 'dynamic1',
      //     },
      //     {
      //       label: `${targetOption.label} Dynamic 2`,
      //       value: 'dynamic2',
      //     },
      //   ];
      //   console.log(this.options)
      //   this.options = [...this.options];
      //   console.log(this.options)
      // }, 1000);
    },
    baseClassAll(option) {
      let that = this
        let params = {
          majorId: that.model.majorId
        }
        getAction(that.url.baseClassAll, params).then((res) => {
          if (res.success) {
            this.classList=res.result;
          } else {
            resolve(res.message)
          }
        })
    },
    baseClassChange(option) {
      console.log(option)
    },
    baseMajorAll(option) {
      let that = this
        let params = {
          departmentId: that.model.departmentId
        }
        getAction(that.url.baseMajorAll, params).then((res) => {
          if (res.success) {
            this.majorList=res.result;
            // res.result.records.forEach((item) => {
            //   item.label = item.name
            //   item.value = item.id
            //   item.isLeaf = false
            //   item.level = 2
            // })
            // resolve(res.result.records)
          } else {
          //  reject(res.message)
          }
        })
    },validateIdCard(rule, value, callback) {
        if (!value || new RegExp(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/).test(value)) {
          callback();
        } else {
          callback("您的身份证号码格式不正确!");
        }
      },
  },
  watch: {
    'state.passwordLevel'(val) {
      console.log(val)
    },
  },
}
</script>
<style lang="less">
.user-register {
  &.error {
    color: #ff0000;
  }

  &.warning {
    color: #ff7e05;
  }

  &.success {
    color: #52c41a;
  }
}

.user-layout-register {
  .ant-input-group-addon:first-child {
    background-color: #fff;
  }
   padding:10px;
   background: #ccf5ef;
}
</style>
<style lang="less" scoped>
.user-layout-register {
  & > h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .register-button {
    width: 50%;
  }

  .login {
    float: right;
    line-height: 40px;
  }
}
::v-deep .sex-form .ant-col label{
  height:40px;
  line-height: 40px;
}
.register_title{
  text-align: center;
  padding: 10px 0;
  font-size: 26px;

}
::v-deep .ant-form-item {
    margin-bottom: 10px !important;
}
::v-deep .ant-select-selection{
  min-height: 35px !important;
}
</style>