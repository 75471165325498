<template>
  <div class="index1">
    <div class="banner">
      <div class="slogan">产品体验，免费试用</div>
    </div>
    <div class="form">
      <a-form-model ref="form" :model="formState" :rules="validatorRules">
        <a-form-model-item required prop="orgName" label="学校/企业名称">
          <a-input placeholder="请输入学校/企业名称" v-model="formState.orgName" />
        </a-form-model-item>
        <a-form-model-item required label="姓名" prop="name">
          <a-input placeholder="请输入姓名" v-model="formState.name" />
        </a-form-model-item>
        <a-form-model-item label="职务">
          <a-input placeholder="请输入职务" v-model="formState.duties" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input placeholder="请输入手机号" v-model="formState.phone" />
        </a-form-model-item>
        <a-form-model-item label="省" prop="provinceCode">
          <a-select placeholder="请选择省份" @change="onChange" v-model="formState.provinceCode">
            <a-select-option :value="item.code" :title="item.name" :key="item.code" v-for="item in pronceList">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="市" prop="cityCode">
          <a-select placeholder="请选择地市" @change="onChange1" v-model="formState.cityCode">
            <a-select-option :value="item.code" :title="item.name" :key="item.code" v-for="item in cityList">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item class="wer" label="">
          <a-checkbox v-model="checked">我已经阅读并同意
            </a-checkbox>
            <a class="a_href"  @click="link('ys')">《隐私条款》</a>
            <a class="a_href" @click="link('fw')">《用户协议》</a>
        </a-form-model-item>
        <a-form-model-item class="wer1" label="">
          <p class="describe">
            温馨提示:毅力实验通产品讲解预约<br />
            1、你的企业可免费享受一次价值1000元的产品顾问一对一讲解服务。<br />
            2、服务由毅力实验通区域服务中心提供，时长约45-60分钟，包含毅力实验通产品功能讲解和行业优秀案例分享。
          </p>
        </a-form-model-item>
        <a-form-model-item class="btn" :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" class="aBtn" @click="onSubmit">提交</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { getProvince, getCity, addYY } from '@/api/api'
export default {
  components: {
   
  },
  data() {
    return {
      checked: false,
      pronceList: '',
      cityList: '',
      formState: {
        cityCode: '',
        cityName: '',
        createTime: '',
        duties: '',
        id: '',
        name: '',
        orgName: '',
        phone: '',
        provinceCode: '',
        provinceName: '',
      },
      validatorRules: {
        //cityCode
        cityCode: [{ required: true, message: '请选择所在地市!' }],
        duties: [{ required: true, message: '请输入姓名!' }],
        name: [{ required: true, message: '请输入姓名!' }],
        orgName: [{ required: true, message: '请输入学校/企业名称!' }],
        phone: [
          { required: true, message: '请输入手机号!' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码!' },
        ],
        code: [{ required: true, message: '请输入任务类名!' }],
        provinceCode: [{ required: true, message: '请输入所在省份!' }],
      },
    }
  },
  created() {
  },
  mounted() {
    this.renderPronce()
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      }
    },
  },
  methods: {
    renderPronce() {
      getProvince().then((res) => {
        if (res.success) {
          this.pronceList = res.result
          //this.homeStatistics=res.result
        }
      })
    },
    renderCity(code) {
      getCity({ code: code }).then((res) => {
        if (res.success) {
          this.cityList = res.result
          //this.homeStatistics=res.result
        }
      })
    },
    onChange(s,s1) {
      this.formState.cityCode = ''
      this.formState.cityName = ''
      this.cityList = []
      this.formState.provinceName = s1.componentOptions.propsData.title
      this.renderCity(s)
    },
    onChange1(s, s1) {
      this.formState.cityName = s1.componentOptions.propsData.title
      this.renderCity(s)
    },
    onSubmit() {
      if(this.checked){
        this.$refs['form'].validate((valid) => {
          if (valid) {
            addYY(this.formState).then((res) => {
              if(res.success){
                this.$message.success(res.message)
                this.formState.duties = "";
                this.checked = false;
                this.$refs['form'].resetFields();
              }else{
                this.$message.warning(res.message)
              }
            }).catch((err) => {
    this.$message.warning(err.message)
  });
          }
        })
      }else{
        this.$message.warning("请同意《毅力实验通》服务")
      }
    },link(str){
      window.open(`/user/${str}`)
    }
  },
}
</script>
<style lang="less" scoped>
.index1 {
  display: contents;
  height: 100%;
  width: 100%;
  background: #f5f5f9 !important;
  .banner {
    background: url('../../../assets/newIndex/yy_banner.png');
    height: 262px;
    width: 1200px;
    margin: 60px auto 0 auto;
    background-repeat: no-repeat;
    //text-align: center;
    // .img{
    //   width:1200px;
    //   height:262px;
    //   margin-top:31px;
    // }
    .slogan {
      margin-left: 75px;
      min-width: 319px;
      height: 132px;
      font-size: 36px;
      font-family: Alibaba PuHuiTi -52 Regular L3, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #ffffff;
      padding-top: 80px;
    }
    .describe {
      width: 339px;
      height: 38px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #ffffff;
      margin-top: 10px;
      margin-left: 75px;
    }
  }
  .form {
    width: 1200px;
    margin: 20px auto 0 auto;
    background: #fff;
    padding: 63px 96px 49px 69px;
    border: 1px solid #ccc;
    border-radius: 8px 8px 8px 8px;
    .ant-form-item {
      display: flex;
      ::v-deep .ant-form-item-label {
        width: 114px !important;
        text-align: right;
        font-size: 15px;
        font-weight: normal;
        color: #eb4d27;
      }
      ::v-deep .ant-form-item-control-wrapper {
        width: 919px !important;
        margin-left: 20px;
        .ant-select-selection__placeholder {
          display: block;
        }
      }
      .a_href {
        min-width: 103px;
        height: 21px;
        font-size: 15px;
        font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #14d0b4;
      }
    }
    .wer {
      min-width: 118px;
      min-height: 21px;
      font-size: 15px;
      font-family: Alibaba PuHuiTi 3-55 Regular L3, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #000000;
      margin-left: 113px !important;
    }
    .wer1 {
      .describe {
        margin-left: 113px !important;
        min-width: 696px;
        height: 64px;
        font-size: 14px;
        font-family: Alibaba PuHuiTi 3-45 Light, Alibaba PuHuiTi 30;
        font-weight: normal;
        color: #a7a7a7;
        line-height: 22px;
      }
    }
    .btn {
      .aBtn {
        margin-left: 113px !important;
        width: 227px;
        height: 40px;
        background: #eb4d27;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
      }
    }
  }
}
</style>